import React, { useState } from "react";
import { Button, Checkbox, TextField, Grid, Paper } from "@material-ui/core";
import PrivacyModal from "components/Privacy/PrivacyOptions";
import Input from "components/InputCustom";
import InputMask from "components/InputMaskCustom";
import "./UserForm.scss"

import { Form } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import firebase from "firebase/app";
import { showNotification, maskPhone, validateCNPJ, unmask } from "helpers/util";
import { domainColorStyle } from "config";
import { validate } from "cpf-check";
import { updateCustomer } from "helpers/apiHelper";
import { phoneNumberTest } from "helpers/phoneNumberTest";
import moment from "moment";
import { clearUserInfo } from "actions/userInfoActions";
import { setZoneForRequestedCep } from "actions/setZoneForRequestedCep";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { userInfoChanged } from "actions/userInfoActions";


export default function PersonalData() {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch()
  const history = useHistory()
  const userInfo = useSelector(({ userInfo }) => userInfo)
  const brandId = useSelector(({ brand }) => brand.brand.id)

  const [userData, setUserData] = useState({})
  const [errors, setErrors] = useState({});
  const [privacy, setPrivacy] = useState(false)
  const [checkedStateRegistration, setCheckedStateRegistration] = useState(userInfo.exempt_business_state_registration)
  const [checkedResponsibleAgreement, setCheckedResponsibleAgreement] = useState(userInfo.responsible_agreement)
  const birthDate = moment(userInfo.birth_date, "DD-MM-YYYY").format("YYYY-MM-DD");
  const responsibleBirthdate = moment(userInfo.responsible_birthdate, "DD-MM-YYYY").format("YYYY-MM-DD")

  const handleChange = (event) => {
    const { name, value, checked } = event.target
    if (name === "exempt_business_state_registration") {
      setCheckedStateRegistration(checked)
    } else if (name === 'responsible_agreement') {
      setCheckedResponsibleAgreement(checked)
    }

    if(name === "phone_number" && value.length === 0){
      setErrors({phone_number:"Preencha o número de telefone"})
    }

    if(name === "responsible_document"){
      const document = unmask(value)
      if(document.length === 0) {
        setErrors({responsible_document: "Preencha este campo."})
      }
    }

    setUserData({ ...userData, [name]: value })
  }

  const onSubmit = (data) => {
    const cpf = userData.cpf ? unmask(userData.cpf) : unmask(userInfo.cpf)
    const phone_number = userData.phone_number ? userData.phone_number : userInfo.phone_number
    const sex = userData.sex ? userData.sex : userInfo.sex
    const responsible_document = userData.responsible_document ? userData.responsible_document : userInfo.responsible_document
    const business_state_registration = userData.business_state_registration ? userData.business_state_registration : userInfo.business_state_registration
    const exempt_business_state_registration = checkedStateRegistration
    const responsible_agreement = checkedResponsibleAgreement

    if (cpf.length <= 11) {
      if (!validate(cpf)) {
        setErrors({ cpf: "CPF inválido" });
        return
      }
    }

    if (cpf.length > 12) {
      const validationResult = validateCNPJ(cpf);
      if (validationResult) {
        setErrors({ cpf: validationResult })
        return
      }
      if (!validate(responsible_document)) {
        setErrors({ responsible_document: "CPF inválido" });
        return
      }
    }

    if (phone_number && !phoneNumberTest(phone_number)) {
      setErrors({ phone_number: "O número de telefone deve ser válido" })
      return;
    }

    if (cpf.length <= 11) {
      updateDataCustomer({
        ...data,
        cpf,
        phone_number,
        sex,
      })
    }

    if (cpf.length > 12) {
      updateDataCustomer({
        ...data,
        cpf,
        phone_number,
        sex,
        responsible_document,
        business_state_registration,
        exempt_business_state_registration,
        responsible_agreement
      })
    }
  }

  const updateDataCustomer = async (customerData) => {
    try {
      const { data } = await updateCustomer(customerData, brandId)
      const customer = {
        ...data.customer,
        id: ((data || {}).customer || {}).id,
      };
      showNotification(
        "top",
        "center",
        "Dados atualizados com sucesso",
        "success"
      );
      dispatch(userInfoChanged(customer));
    } catch (error) {
      console.log(error);
      showNotification(
        "top",
        "center",
        "Erro ao fazer a atualização, entre em contato ou tente novamente",
        "danger"
      );
    }
  };


  const logOffCustomer = () => {
    localStorage.clear()
    dispatch(clearUserInfo())
    firebase.auth().signOut();
    history.push("/");
    setZoneForRequestedCep({ data: { cep: null } });
  }

  return (
    <Paper className="paper-container-person-data" style={{ width: "100%" }}>
      {userInfo?.cpf?.length <= 11 &&
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} style={{ marginTop: '2em', padding: "1em" }}>
            <Grid item xs={12}>
              <span className="span-info-user-data">Nome*</span>
              <Input
                required
                invalid={false}
                placeholder="Nome*"
                name="name"
                showLabel={false}
                onChange={handleChange}
                defaultValue={userInfo.name}
                innerRef={register()}
              />
            </Grid>
            <Grid item xs={12}>
              <span className="span-info-user-data">CPF*</span>
              <InputMask
                disabled={!!userInfo.cpf}
                required
                name="cpf"
                showLabel={false}
                error={errors.cpf}
                onChange={handleChange}
                defaultValue={userInfo.cpf}
                mask="999.999.999-99"
                inputMode="numeric"
                onFocus={() => setErrors({})}
              />
            </Grid>
            <Grid item xs={12}>
              <span className="span-info-user-data">E-mail*</span>
              <Input
                required
                type="email"
                placeholder="example123@gmail.com"
                name="email"
                showLabel={false}
                onChange={handleChange}
                defaultValue={userInfo.email}
                innerRef={register()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <span className="span-info-user-data">Celular*</span>
              <InputMask
                required
                placeholder="Celular*"
                name="phone_number"
                showLabel={false}
                onChange={handleChange}
                defaultValue={maskPhone(userInfo.phone_number)}
                inputMode="numeric"
                mask="(99)99999-9999"
                error={errors.phone_number}
                onFocus={() => setErrors({})}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <span className="span-info-user-data">Data de nascimento*</span>
              <Input
                required
                type="date"
                name="birth_date"
                showLabel={false}
                onChange={handleChange}
                innerRef={register()}
                defaultValue={birthDate}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <span className="span-info-user-data">Sexo</span>
              <TextField
                select
                label="Sexo"
                name="sex"
                onChange={handleChange}
                variant="outlined"
                fullWidth
                margin="normal"
                defaultValue={userInfo.sex}
                SelectProps={{
                  native: true,
                }}
              >
                <option value="uninformed">Prefiro não informar</option>
                <option value="female">Feminino</option>
                <option value="male">Masculino</option>
                <option value="other">Outro</option>
              </TextField>
            </Grid>

            <Grid xs={12} style={{ marginTop: 10 }}>
              <Button
                onClick={() => { setPrivacy(true) }}
                className="btn button-privacy"
                style={domainColorStyle("color")}>
                <i className="fas fa-file-alt" style={{ marginRight: 5 }} />
                Privacidade
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                type={"submit"}
                className="btn btn-block"
                style={{ ...domainColorStyle("color"), textTransform: "none" }}>
                Atualizar
              </Button>
              <Button
                onClick={() => logOffCustomer()}
                className="btn btn-block"
                variant="contained"
                color="primary"
                style={{ textTransform: "none", marginTop: "2%" }}>
                Sair
              </Button>
            </Grid>
          </Grid>
        </Form>}

      {userInfo?.cpf.length > 12 &&
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} style={{ marginTop: '2em', padding: "1em" }}>
            <Grid item xs={12} sm={6}>
              <span className="span-info-user-data">Razão Social*</span>
              <Input
                required
                invalid={false}
                placeholder="Razão Social*"
                name="responsible_name"
                showLabel={false}
                onChange={handleChange}
                defaultValue={userInfo.responsible_name}
                innerRef={register()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <span className="span-info-user-data">Nome fantasia</span>
              <Input
                invalid={false}
                placeholder="Nome fantasia"
                name="trade_name"
                showLabel={false}
                onChange={handleChange}
                defaultValue={userInfo.trade_name}
                innerRef={register()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <span className="span-info-user-data">
                CNPJ*
              </span>
              <InputMask
                disabled={!!userInfo.cpf}
                mask="99.999.999/9999-99"
                error={errors.cpf}
                required
                placeholder="CNPJ*"
                name="cpf"
                showLabel={false}
                onFocus={() => setErrors({})}
                onChange={handleChange}
                defaultValue={userInfo.cpf}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <span className="span-info-user-data">
                Inscrição Estadual
              </span>
              <InputMask
                disabled={checkedStateRegistration}
                mask="99999999-9"
                invalid={false}
                placeholder="Inscrição Estadual"
                name="business_state_registration"
                showLabel={false}
                onChange={handleChange}
                defaultValue={userInfo.business_state_registration}
              />
              <Checkbox
                checked={checkedStateRegistration}
                onChange={handleChange}
                name="exempt_business_state_registration"
                color="default"
              />
              <label style={{marginLeft: -10}}>Isento</label>
            </Grid>
            <Grid item xs={12} sm={6}>
              <span className="span-info-user-data">E-mail*</span>
              <Input
                required
                type="email"
                placeholder="example123@gmail.com"
                name="email"
                showLabel={false}
                onChange={handleChange}
                defaultValue={userInfo.email}
                innerRef={register()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <span className="span-info-user-data">Celular*</span>
              <InputMask
                required
                placeholder="Celular*"
                name="phone_number"
                showLabel={false}
                onChange={handleChange}
                defaultValue={maskPhone(userInfo.phone_number)}
                innerRef={register()}
                inputMode="numeric"
                mask="(99)99999-9999"
                error={errors.phone_number}
                onFocus={() => setErrors({})}
              />
            </Grid>

            <div className="person-data-my-account">
              <span>Responsável</span>
            </div>

            <Grid item xs={12}>
              <span className="span-info-user-data">Nome*</span>
              <Input
                required
                invalid={false}
                placeholder="Nome*"
                name="name"
                showLabel={false}
                onChange={handleChange}
                defaultValue={userInfo.name}
                innerRef={register()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <span className="span-info-user-data">CPF*</span>
              <InputMask
                required
                name="responsible_document"
                showLabel={false}
                error={errors.responsible_document}
                onChange={handleChange}
                defaultValue={userInfo.responsible_document}
                mask="999.999.999-99"
                inputMode="numeric"
                onFocus={() => setErrors({})}
                innerRef={register()}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <span className="span-info-user-data">Data de nascimento*</span>
              <Input
                required
                type="date"
                name="responsible_birthdate"
                showLabel={false}
                onChange={handleChange}
                innerRef={register()}
                defaultValue={responsibleBirthdate}
              />
            </Grid>
            <Grid item xs={12}>
              <span className="span-info-user-data">
                Cargo
              </span>
              <Input
                invalid={false}
                placeholder="Cargo"
                name="responsible_position"
                showLabel={false}
                onChange={handleChange}
                defaultValue={userInfo.responsible_position}
                innerRef={register()}
              />
              <Checkbox
                checked={checkedResponsibleAgreement}
                onChange={handleChange}
                name="responsible_agreement"
                color="default"
              />
              <label style={{marginLeft: -10}}>Declaro ser o responsável legal dessa empresa</label>
            </Grid>
            <Grid xs={12} style={{ marginTop: 10 }}>
              <Button
                onClick={() => { setPrivacy(true) }}
                className="btn button-privacy"
                style={domainColorStyle("color")}>
                <i className="fas fa-file-alt" style={{ marginRight: 5 }} />
                Privacidade
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                type={"submit"}
                className="btn btn-block"
                style={{ ...domainColorStyle("color"), textTransform: "none" }}>
                Atualizar
              </Button>
              <Button
                onClick={logOffCustomer}
                className="btn btn-block"
                variant="contained"
                color="primary"
                style={{ textTransform: "none", marginTop: "2%" }}>
                Sair
              </Button>
            </Grid>
          </Grid>
        </Form>}

      <PrivacyModal
        userId={userInfo.id}
        isOpen={privacy}
        toggleModal={() => setPrivacy(false)}
      />
    </Paper>
  )
}