import React, { useEffect, useState } from "react";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import DiscountIcon from "@material-ui/icons/LocalOffer";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Box } from "@material-ui/core";
import ShortcutBtn from "./ShortcutBtn/ShortcutBtn";
import { useHistory, useParams } from "react-router-dom";
import { Motorcycle } from "@material-ui/icons";

import "./RedirectMenu.scss";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedCategory } from "actions/categoriesActions";
import { getFreeDeliveryProducts } from "helpers/apiHelper";
import { extractItemsFromMixes } from "helpers/storeHelper";
import { getFlipBooks } from "helpers/apis/flipbooksApi";
import { freeDeliveryProductsChanged } from "actions/productsActions";

const RedirectMenu = () => {
  const history = useHistory();
  const { market_id } = useParams();
  const brand = useSelector(({ brand }) => brand?.brand);
  const userId = useSelector(({ userInfo }) => userInfo?.id);
  const dispatch = useDispatch();
  const deliveryMethodId = useSelector(
    ({ deliveryMethod }) => deliveryMethod?.deliveryMethod.id
  );
  const neighborhoodId = useSelector(({zone}) => zone?.neighborhood_id)
  const [hasFreeDeliveryItems, setHasFreeDeliveryItems] = useState(false)
  const [hasFlipbooks, setHasFlipbooks] = useState(false)

  const fetchFlipbooks = async () => {
    try {
      const { flipbooks } = await getFlipBooks(market_id)
      setHasFlipbooks(flipbooks.length > 0)
    } catch (error) {
      setHasFlipbooks(false)
    }
  }

  const fetchFreeDeliveryProducts = async () => {
    const response = await getFreeDeliveryProducts(
      market_id,
      deliveryMethodId,
      userId,
      neighborhoodId
    );
    const items = extractItemsFromMixes(response)
    dispatch(freeDeliveryProductsChanged(items))
    setHasFreeDeliveryItems(items.length > 0);
  };

  useEffect(() => {
    fetchFlipbooks()
    fetchFreeDeliveryProducts()
  }, [])

  const redirect = (path) => {
    switch (path) {
      case "clube":
        dispatch(changeSelectedCategory({ id: "clube" }));
        history.push(`/loja/${market_id}/categoria/clube`);
        break;
      case "encartes":
        history.push(`/loja/${market_id}/encartes`);
        break;
      case "cupons":
        history.push(`/user/cupons`);
        break;
      case "listas":
        history.push(`/user/listas`);
        break;
      case "fretegratis":
        dispatch(changeSelectedCategory({ id: "fretegratis" }));
        history.push(`/loja/${market_id}/categoria/fretegratis`);
      default:
        break;
    }
  };

  return (
    <Box className="redirect-menu">
      {brand?.crm && (
        <ShortcutBtn
          label={brand.crm.club}
          icon={<MonetizationOnIcon />}
          onClick={() => redirect("clube")}
        />
      )}
      <ShortcutBtn
        label="Cupons"
        icon={<ConfirmationNumberIcon />}
        onClick={() => redirect("cupons")}
      />
      {hasFreeDeliveryItems && (
        <ShortcutBtn
          label="Frete Grátis"
          icon={<Motorcycle />}
          onClick={() => redirect("fretegratis")}
        />
      )}
      {hasFlipbooks ? (
        <ShortcutBtn
          label="Encartes"
          icon={<DiscountIcon />}
          onClick={() => redirect("encartes")}
        />
        ) : null}
      <ShortcutBtn
        label="Listas"
        icon={<ListAltIcon />}
        onClick={() => redirect("listas")}
      />
    </Box>
  );
};

export default RedirectMenu;
