import { domainColorStyle } from "config";
import { sendCustomerInfoEvent } from "helpers/analyticHelper";
import { userInfoChanged } from "actions/userInfoActions";
import "./UserPage.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/app";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col,
  Row,
} from "reactstrap";
import "./UserPage.scss";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import LoadingContent from "components/LoadingContent";
import UserForm from "components/UserPage/UserForm/UserForm";
import OrdersList from "components/UserPage/OrdersList/OrdersList";
import ShoppingLists from "components/UserPage/ShoppingLists/ShoppingLists";
import NotificationCenter from "new/components/UserPage/NotificationCenter/NotificationCenter";
import CardLIstingMenu from "new/components/UserPage/PaymentCardListing/CardLIstingMenu/CardLIstingMenu";
import PersonIcon from '@material-ui/icons/Person';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ListAltIcon from '@material-ui/icons/ListAlt';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { UserModal } from "new/components/UserPage/UserModal/UserModal";
import { getUiConfig } from "enums/uiConfig";
import { getCustomer } from "helpers/apiHelper";
import { useHistory } from "react-router-dom";
import Coupons from "components/UserPage/Cupom/Coupons";
import FriendCampaign from "components/UserPage/FriendCampaign/FriendCampaign";
import UserLoginModal from "components/UserLoginModal/UserLoginModal";
import { getDomainMarketColor } from "config";
import { getAccessToken } from "helpers/apis/oAuthApi";
import { arrayHasContent } from "helpers/util";
import { handleTokenErrors } from "helpers/apis/oAuthApi";
import { notification } from "helpers/util";

const UserPage = ({ location }) => {
  const userInfo = useSelector(({ userInfo }) => userInfo);
  const brand = useSelector(({ brand }) => brand.brand);
  const dispatch = useDispatch();
  const history = useHistory();
  const [sections, setSections] = useState([
    "Dados cadastrais",
    "Central de Cupons",
    "Pedidos",
    "Cartões",
    "Listas de Compras",
    "Central de Notificações",
  ]);
  const [loading, setLoading] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [uiConfig, setUiConfig] = useState(null);
  const [firebaseRecoveredInfo, setFirebaseRecoveredInfo] = useState(null);
  const userId = userInfo.id || localStorage.getItem("USER_ID");
  const [openUserModal, setOpenUserModal] = useState(false)
  const showFirebaseOptions = !userId && arrayHasContent(uiConfig?.signInOptions) && !Boolean(firebaseRecoveredInfo) && !loading

  const iconMap = {
    "Dados cadastrais": <PersonIcon />,
    "Central de Cupons": <ConfirmationNumberIcon />,
    "Pedidos": <ReceiptIcon />,
    "Cartões": <CreditCardIcon />,
    "Listas de Compras": <ListAltIcon />,
    "Central de Notificações": <NotificationsIcon />
  }

  useEffect(() => {
    if (brand) getAuthConfig(brand);
    authObserver();
    handleRedirectTab();
  }, []);

  const handleRedirectTab = () => {
    if (window.location.pathname.includes("cupons")) {
      setActiveTabIndex(1);
      return
    }
    
    if (location.state?.my_orders) {
      setActiveTabIndex(2);
      return 
    }

    if (window.location.pathname.includes("listas")) {
      setActiveTabIndex(4);
      return
    } 

    setActiveTabIndex(0)
  };

  const getAuthConfig = (brand) => {
    const response = getUiConfig(brand);
    setUiConfig(response);
  };

  const authObserver = () => {
    setLoading(true);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setFirebaseRecoveredInfo({
          name: user?.displayName,
          phone_number: user?.phoneNumber?.split("+55").join(""),
          email: user?.email,
        });
        sendCustomerInfoEvent();
      }
      setLoading(false);
    });
  };

  const removeSectionByName = (sectionName) => {
    setSections(sections.filter((section) => section !== sectionName));
  };

  const handleCustomerData = (customerData) => {
    const {
      name,
      cpf,
      email,
      birth_date,
      phone_number,
      id,
      responsible_agreement,
      responsible_document,
      responsible_name,
      responsible_position,
      responsible_birthdate,
      business_state_registration,
      trade_name,
      exempt_business_state_registration,
      sex
    } = customerData;
    localStorage.setItem("USERNAME", name);
    dispatch(
      userInfoChanged({
        ...userInfo,
        name,
        cpf,
        email,
        birth_date,
        phone_number,
        id,
        responsible_agreement,
        responsible_document,
        responsible_name,
        responsible_position,
        responsible_birthdate,
        business_state_registration,
        trade_name,
        exempt_business_state_registration,
        sex
      })
    );
  }

  const findCustomer = async () => {
    setLoading(true);
    try {
      const { data } = await getCustomer()
      handleCustomerData(data.customer)
    } catch (error) {
      console.log(error)
      notification("Erro ao buscar dados do usuário", "danger")
    } finally {
      setActiveTabIndex(0);
      setLoading(false);
    }
  };

  const toggleTab = (index) => {
    if (activeTabIndex !== index) {
      setActiveTabIndex(index);
    }
  };

  const getActiveTabStyles = (index) => {
    const mainColor = getDomainMarketColor();
    return index === activeTabIndex ? {
        color: mainColor,
        borderBottom: `2px solid ${mainColor}`,
        borderStyle: 'none none solid none'
    } : {};
};

const getToken = async (cpf) => {
  setLoading(true);
  try {
    const response = await getAccessToken(cpf)
    if (response.data) {
      handleTokenErrors(response.data)
    }
    
    response.access_token
      ? findCustomer()
      : setOpenUserModal(true)
  } catch (error) {
    console.log(error)
    setOpenUserModal(true)
  } finally {
    setLoading(false);
  }
}

  useEffect(() => {
    if (firebaseRecoveredInfo && !userInfo?.id) {
      getToken(userInfo?.cpf || null)
    }
  }, [firebaseRecoveredInfo, userInfo])

  switch (true) {
    case loading:
      return (
        <div style={{ paddingTop: 500 }}>
          <LoadingContent isLoading={loading} />
        </div>
      );
    case showFirebaseOptions:
      return (
        <section className="section">
          <Container className="mt-5">
            <UserLoginModal
              showModal={showFirebaseOptions}/>
          </Container>
        </section>
      );
    default:
      return (
        <section className="section user-page">
          {firebaseRecoveredInfo && openUserModal && (
            <UserModal
              isOpen={openUserModal}
              firebaseRecoveredInfo={firebaseRecoveredInfo}
            />
          )}
          <Container className="mt-5">
            <Row className="mb-2">
              <Col xs={12} md={1}>
                <IconButton
                  style={{ ...domainColorStyle("color"), borderRadius: 5 }}
                  size="small"
                  onClick={() => history.goBack()}
                  color="primary"
                >
                  <ArrowBack
                    style={domainColorStyle("fill")}
                    fontSize="inherit"
                  />
                  Voltar
                </IconButton>
              </Col>
            </Row>
            <Nav tabs style={{ justifyContent: "space-around", marginBottom: "1.5rem" }}>
              {sections.map((item, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={
                      activeTabIndex === index ? "nav-link active" : "nav-link"
                    }
                    style={getActiveTabStyles(index)}
                    onClick={() => toggleTab(index)}
                  >
                    {iconMap[item]}
                    {item}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            {userId ? (
              <TabContent activeTab={activeTabIndex}>
                <TabPane tabId={0}>
                  <UserForm/>
                </TabPane>
                <TabPane tabId={1}>
                  <Coupons />
                </TabPane>
                <TabPane tabId={2}>
                  <OrdersList />
                </TabPane>
                <TabPane tabId={3}>
                  <CardLIstingMenu removeSection={removeSectionByName} />
                </TabPane>
                <TabPane tabId={4}>
                  <ShoppingLists />
                </TabPane>
                <TabPane tabId={5}>
                  <NotificationCenter />
                </TabPane>
                <TabPane tabId={6}>
                  <FriendCampaign removeSection={removeSectionByName} />
                </TabPane>
              </TabContent>
            ) : null}
          </Container>
        </section>
      );
  }
};

export default UserPage;
