import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Container, Grid, Backdrop, CircularProgress, Box, Button, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import firebase from "firebase/app";
import Recaptcha from "react-google-invisible-recaptcha";
import { v4 as uuidv4 } from "uuid";
import ReactGA from "react-ga";

import OrderDetails from "./components/OrderDetails/OrderDetails";
import OrderItems from "./components/OrderItems/OrderItems";
import OrderCoupon from "./components/OrderCoupon/OrderCoupon";
import OrderPreferences from "./components/OrderPreferences/OrderPreferences";
import OrderDeliveryInfos from "./components/OrderDeliveryInfos/OrderDeliveryInfos";
import OrderDeliveryTime from "./components/OrderDeliveryTime/OrderDeliveryTime";
import OrderClientInfos from "./components/OrderClientInfos/OrderClientInfos";
import OrderPaymentInfos from "./components/OrderPaymentInfos/OrderPaymentInfos";
import OrderFinalized from "./components/OrderFinalized/OrderFinalized";
import ConfirmTermsModal from "components/ConfirmTermsModal/ConfirmTermsModal";

import { getRecaptchaSiteKey } from "config";
import { getCupons } from "helpers/apis/cuponsApi";
import { getOperatingTimes, payWithMerconnectPay, createOrder, createLog, getDiscounts2 } from "helpers/apiHelper";
import { getMixesFromCart, getProductsTotal, discountsFormattedCart, getCartTotal } from "helpers/cartHelper";
import { getMissingItemsOption, getCustomBags, getCorreiosShippingMethods } from "helpers/apis/marketInfo";
import { notification, countItensCart, fixCartForErrors, showNotification, generateOnlinePayParams, generateOrderCreateParams, orderValid } from "helpers/util";
import { track, trackCustom } from "helpers/pixelHelper";
import { removeWholeProduct, replaceCartProduct } from "actions/cartActions";
import { sendTransactionEvent } from "helpers/apis/impulseEventsApiHelper";
import { sendInitiateCheckoutEvent, sendPurchasedEvent } from "helpers/analyticHelper";
import { setOrderId, setOrderNumber } from "actions/clearsaleActions";
import getOneSignalPlayerId from "helpers/oneSignalHelper";
import numberToCurrency from "helpers/currency";
import { confirmAlert } from "react-confirm-alert";
import { timeZoneAdjustedMoment } from "helpers/util";
import { getDomainGATrackingID } from "config";
import { updateBrandCustomer } from "helpers/apiHelper";
import NotificationCenterWarningModal from "components/NotificationCenterWarningModal/NotificationCenterWarningModal";
import OrderRelatedItems from "./components/OrderRelatedItems/OrderRelatedItems";
import { checkCaptcha } from "helpers/apis/recaptchaCheckApi";
import { getDeliveryMethods } from "helpers/apis/marketInfo";
import { setDeliveryMethod } from "actions/setDeliveryMethod";
import Warning from "components/Warning/Warning";
import { getOrderStatus } from "helpers/apiHelper";
import SimpleDialogs from "new/components/Modal/SimpleDialogs/SimpleDialogs";
import { getAppInfos } from "helpers/apis/marketInfo";
import { createPagaLeveCheckout } from "helpers/apiHelper";
import { authorizePagaLeveCheckout } from "helpers/apiHelper";
import { sendAllyHigherValueCaptureStatus } from "helpers/apiHelper";
import { formatDeadlineToDateString } from "helpers/util";
import { WithholdRevenueWarning } from "new/components/WithholdRevenue";



function Checkout({ getClearsaleFingerPrint }) {
  const deliveryMethod = useSelector(({ deliveryMethod }) => deliveryMethod);
  const cart = useSelector(({ cart }) => getMixesFromCart(cart.cart));
  const market = useSelector(({ markets }) => markets);
  const neighborhood_id = useSelector(({ zone }) => zone.neighborhood_id);
  const zone = useSelector(({ zone }) => zone);
  const userInfo = useSelector(({ userInfo }) => userInfo);
  const productsWithPrepTime = cart.filter((item) => item.preparation_time_in_hours);
  const [discounts, setDiscount] = useState({ discounts: [] });
  const [coupons, setCoupons] = useState(undefined);
  const [missing_items, setMissingItems] = useState({
    options: [],
    selected_option: "",
  });
  const [custom_bags, setCustomBags] = useState({
    custom_bags: [],
  });
  const [request_custom_bags, setRequestBags] = useState({ custom_bag_id: 0 });
  const [preferences, setPreferences] = useState("");
  const [showAdrressModal, setShowAddressModal] = useState(false);
  const [showDeliveryTimesModal, setShowDeliveryTimesModal] = useState(false);
  const [deliveryTime, setDeliveryTime] = useState({});
  const [operatingTimes, setOperatingTimes] = useState([]);
  const [postalMethodValues, setPostalMethodValues] = useState([])
  const [serviceCodeCorreios, setServiceCodeCorreios] = useState('')
  const [selectedPostalData, setSelectedPostalData] = useState(null)
  const [loading, setLoading] = useState(true);
  const [componentLoading, setComponentLoading] = useState(false)
  const [paymentSelectedType, setPaymentSelectedType] = useState({});
  const [numberCreditInstallments, setNumberCreditInstallments] = useState({});
  const [paymentInfosEncrypted, setPaymentInfosEncrypted] = useState({});
  const [cardModalInfo, setShowCardModalInfo] = useState(false);
  const [showOrderStatus, setShowOrderStatus] = useState(false)
  const [checkOrderStatus, setCheckOrderStatus] = useState({})
  const [order, setOrder] = useState({});
  const [resolvedRecaptcha, setResolvedRecaptcha] = useState(false);
  const [cash, setCash] = useState("");
  const [termsOfUseVersionModal, setTermsOfUseVersionModal] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [formError, setFormError] = useState(null)
  const [signedIn, setSignedIn] = useState(false)
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [firebaseRecoveredInfo, setFirebaseRecoveredInfo] = useState({})
  const [brandCashbackCoupons, setBrandCashbackCoupons] = useState([])
  const brand = useSelector(({ brand }) => brand.brand)
  const personType = localStorage.getItem("personType")

  const total_products = getProductsTotal(cart);

  const awareHigherValueCapture = useSelector(({cart}) => cart?.awareHigherValueCapture)
  const higherValueLimit = useSelector(({cart}) => cart?.higherValueLimit) || 0

  const acceptsHigherValueCapturing = awareHigherValueCapture ? (higherValueLimit > 0) : false;

  const dispatch = useDispatch();
  const history = useHistory();

  let recaptcha = useRef(null);
  let sessionIdReceiver = useRef(null);
  const hasItemsWithRevenue = cart.some(item => Boolean(item?.withhold_revenue))

  async function deliveryTaxObserver() {
    const restrictDelivery =  !market.restrict_delivery_methods_by_person_type ? "" : personType
    const { data } = await getDeliveryMethods(
      "delivery",
      zone.cep,
      zone.neighborhood_id,
      market.id,
      restrictDelivery
     );
    const current_delivery_info = data.find(
      (method) => method.id === deliveryMethod.deliveryMethod.id
    );
    if (deliveryMethod.deliveryMethod.needs_full_address) {
      current_delivery_info.tax = deliveryMethod.deliveryMethod.tax
    }
    if (deliveryMethod.deliveryMethod.tax !== current_delivery_info?.tax) {
      dispatch(setDeliveryMethod(current_delivery_info));
      history.go(0)
    }
  }

  const total_value = getCartTotal(
    cart,
    market,
    deliveryMethod.deliveryMethod.mode === "drivethru",
    discounts.total_discount,
    parseFloat(request_custom_bags.custom_bag_value || 0),
    deliveryMethod.deliveryMethod
  );

  const authObserver = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setFirebaseRecoveredInfo({
          name: user?.displayName,
          phone_number: user?.phoneNumber?.split("+55").join(""),
          email: user?.email,
        })
        setSignedIn(!!user);
      }
    });
  };

  useEffect(() => {
    authObserver();
    verifyClearsale();
    if (deliveryMethod.deliveryMethod.mode === "delivery") {
      deliveryTaxObserver();
    }
  }, [userInfo]);

  useEffect(() => {
    const notificationCentralWarningSeen = userInfo?.brand_customer?.notification_central_warning_seen
    if (userInfo?.phone && !notificationCentralWarningSeen) {
      setNotificationModalOpen(true);
      updateBrandCustomer(userInfo, brand.id)
    }
  }, [userInfo]);

  const getPrivacyPolicy = async () => {
    const { app_infos } = await getAppInfos()
    const privacyPolicy = app_infos.find((info) => info.codename === "privacy_policy");
    setPrivacyPolicy(privacyPolicy);
    if (
      privacyPolicy?.version !== userInfo.terms_of_use_version &&
      userInfo.id
    ) {
      setTermsOfUseVersionModal(true);
    }
  }

  useEffect(() => {
    if (!privacyPolicy) {
      getPrivacyPolicy()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privacyPolicy]);

  function verifyClearsale() {
    const { anti_fraud_app_code } = market;

    verifyTotalPriceAndRedirectBackIfNeeded();

    if (anti_fraud_app_code) {
      getClearsaleFingerPrint(
        anti_fraud_app_code,
        "clearsaleSessionIdReceiver"
      );
    }
  }

  function verifyTotalPriceAndRedirectBackIfNeeded() {
    const totalItensAmount = countItensCart(cart);
    if (
      !deliveryMethod.deliveryMethod.mode ||
      totalItensAmount <= 0 ||
      !market.id
    ) {
      history.push("/");
    }
    if (
      Number(total_products) < deliveryMethod.min_order_price ||
      (totalItensAmount > deliveryMethod.max_items_amount &&
        deliveryMethod.max_items_amount != null)
    ) {
      history.push("/");
    } else {
      validationMessageCart();
      getApiDiscounts();
      getMarketCupons();
      missingItemsOptions();
      getCustomFlags();
      updateOperatingTimes();
      getCorreiosEstimatives();
    }
  }

  const getApiDiscounts = async (coupon_code, order_cart) => {
    let params = {
      neighborhood_id,
      is_free_of_delivery_tax: deliveryMethod.deliveryMethod.tax <= 0,
      order_subtotal: parseFloat(total_products),
      market_id: market.id,
      coupon_code: coupon_code || null,
      order_items: discountsFormattedCart(order_cart || cart),
    };
    try {
      const response = await getDiscounts2(params);
      if (response.error_message) {
        notification(response.error_message, "warning");
        setDiscount(response);
      } else {
        if (discounts.discounts.length > response.discounts.length) {
          notification(
            "O cupom adicionado não é mais válido para esse pedido.",
            "warning"
          );
        }
        setDiscount(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMarketCupons = async () => {
    const { discount_coupons } = await getCupons(market.id);
    setCoupons(discount_coupons);
  };

  async function missingItemsOptions() {
    try {
      const data = await getMissingItemsOption(market.id);
      setMissingItems({
        options: data.missing_items_options,
        selected_option: data.missing_items_options[0]?.description,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const getCustomFlags = async () => {
    try {
      const response = await getCustomBags(market.id);
      setCustomBags({
        custom_bags: response.custom_bags,
      });
    } catch (error) {
      console.log(error);
      notification(
        "Erro ao buscar informaçõe de autenticação. Entre em contato com o suporte!",
        "error"
      );
    }
  };

  function updateOperatingTimes() {
    const cartIDs = cart.map(item => item.id)
    getOperatingTimes(
      market.id,
      zone.neighborhood_id,
      deliveryMethod.deliveryMethod.id, cartIDs,
      (error, data) => {
        if (error) {
          notification(
            "Ocorreram erros, verifique sua conexão com a internet e tente novamente!",
            "danger"
          );
          setLoading(false);
        } else {
          setOperatingTimes(data.operating_times);
          //if importante para realizar o pagamento via pix parcelado
          if(localStorage.getItem("pagaLeveOrder")){
            setLoading(true)
          }else{
            setLoading(false);
          }
        }
      }
    );
  }

  const getCorreiosEstimatives = async () => {
    const cartData = cart.map(cartItem => ({ item_id: cartItem.id, amount: cartItem.amount }))
    if(deliveryMethod.deliveryMethod.tax_calculation_method === 'correios'){
      setComponentLoading(true)
      try {
        const response = await getCorreiosShippingMethods(market.id, JSON.stringify(cartData), zone.cep)
        if (response.data) {
          setPostalMethodValues(response.data.estimates);
          setComponentLoading(false)
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleChangeValueCorreios = (e) => {
    const {value} = e.target
    setServiceCodeCorreios(value);
    const correio = postalMethodValues.find(correio => correio.code === value)
    setSelectedPostalData(correio)
    const deadlineDateString = formatDeadlineToDateString(correio.deadline)
    setDeliveryTime({
      start_time: deadlineDateString,
      end_time: deadlineDateString,
      schedule_until: deadlineDateString,
      enabled: true,
    })
  };

  function executeRecaptcha(e) {
    const validationMessage = orderValidationMessage()
    const error = orderValid(deliveryMethod.deliveryMethod, deliveryTime, userInfo, paymentSelectedType, cash, total_value, selectedPostalData) || orderValidationMessage(true);
    if (error) {
      const errorMessage = validationMessage !== "" ? validationMessage : error.message
      const notificationType = validationMessage !== "" ? "warning" : "danger"
      notification(errorMessage, notificationType);
      setFormError(error.position);
      error.position === 5 && updateOperatingTimes()
      setTimeout(() => {
        setFormError(null);
      }, 10000);
    } else {
      if (recaptcha) {
        recaptcha.current.execute();
      }
    }
  }



  const redirectToPagaLeve = async (order) => {
    try {
      const { checkout_url } = await createPagaLeveCheckout(order.id)
      window.location.replace(checkout_url)
      localStorage.setItem("pagaLeveOrder", JSON.stringify(order));
      setLoading(false);
    } catch (error) {
      notification(
        "Ocorreram erros com essa opção de pagamento, tente novamente",
        "danger"
      );
      setLoading(false)
    }
  }

  const getPagaLeveAuth = async (order) => {
    const totalPrice = parseFloat(order.total_price)
    try {
      const response = await authorizePagaLeveCheckout(order.id, totalPrice);
      if(response) {
        notification('Pix realizado', 'success')
        trackCustom("OnlinePurchase");
        dispatch(setOrderId(order.id));
        dispatch(setOrderNumber(order.order_number));
        localStorage.removeItem("pagaLeveOrder")
      }
    } catch (error) {
      notification("Ocorreram erros ao processar seu pagamento, tente novamente", "danger")
      console.log(error)
    }finally{
      setOrder(order)
      localStorage.removeItem("pagaLeveOrder")
    }
  }

  useEffect(() => {
    const pagaLeveOrder = JSON.parse(localStorage.getItem("pagaLeveOrder"))
    if (pagaLeveOrder) {
      const delay = 6000;
      setTimeout(() => {
        getPagaLeveAuth(pagaLeveOrder);
      }, delay)
    }
  }, [])

  const onSubmit = async () => {
    const recaptchaToken = recaptcha?.current?.getResponse()

    const isCaptchaValid = await checkCaptcha(recaptchaToken)
    if (!isCaptchaValid) {
      alert("Falha na verificação do recaptcha")
      recaptcha.current.reset()
      return
    }

    setLoading(true)
    const app_generated_token = uuidv4()
    let oneSignalId = await getOneSignalPlayerId();
    if (order.order_id && paymentSelectedType.codename !== "stone_pix") {
      this.payOnline();
    } else {
      const method_delivery = deliveryMethod.deliveryMethod;
      const coupon_discounts = discounts.discounts.filter((item) => item.coupon_code)
      const error = orderValid(method_delivery, deliveryTime, userInfo, paymentSelectedType, cash, total_value, selectedPostalData);
      const clubMember = !!localStorage.getItem("crmUserCpf")
      if (!error) {
        const params = generateOrderCreateParams(
          app_generated_token,
          coupon_discounts[0]?.coupon_code || "",
          paymentSelectedType,
          method_delivery,
          market,
          deliveryTime,
          cart,
          userInfo,
          missing_items,
          custom_bags,
          cash,
          preferences,
          numberCreditInstallments,
          clubMember,
          higherValueLimit,
          acceptsHigherValueCapturing,
          selectedPostalData  
        );

        params.order.client_attributes.antifraud_session_id = sessionIdReceiver?.current?.value
        params.order.client_attributes.push = oneSignalId || "e062f13a-ef70-11ed-a05b-mercadapp";
        params.order.client_attributes.visitor_identifier =
          localStorage.deviceId;
        createOrder(market.id, params, (error, data) => {
          if (error) {
            console.log(error)
            setResolvedRecaptcha(false);
            setLoading(false);
            if (error.response) {
              const responseData = error.response.data;
              if ((responseData.problems || []).length > 0) {
                fixCartForErrors(
                  responseData,
                  cart,
                  market,
                  removeWholeProduct,
                  replaceCartProduct,
                  history
                );
              } else {
                notification(
                  responseData.message
                    ? responseData.message
                    : "Ocorreram erros ao finalizar sua compra",
                  "danger"
                );
              }
            } else {
              notification("Ocorreram erros ao finalizar sua compra", "danger");
            }
          } else {
            handleBrandCashbackCoupons(data)
            const { total_price, id, order_number, subtotal_with_additions } =
              data.order;
            track("Purchase", {
              value: total_price,
              currency: "BRL",
            });
            if (getDomainGATrackingID() && brand.id === 169) {
              ReactGA.event({
                category: "conversion",
                value: total_price,
                currency: "BRL",
                transaction_id: id
              });
            }
            sendTransactionEvent(id, total_price);
            sendInitiateCheckoutEvent({ data, items: cart });
            if (paymentSelectedType.accepts_higher_value_capturing) {
              const status = acceptsHigherValueCapturing ? "approved" : "disapproved"
                sendAllyHigherValueCaptureStatus(market?.id, status)
              }
            if (
              paymentSelectedType.kind === "online" &&
              paymentSelectedType.codename !== "stone_pix" &&
              paymentSelectedType.codename !== "paga_leve_pix"
            ) {
              payOnline(data.order);
            } else if (paymentSelectedType.codename === "stone_pix") {
              payPix(data.order);
            } else if (paymentSelectedType.codename === "paga_leve_pix") {
              redirectToPagaLeve(data.order)
            } else {
              setLoading(false);
              setOrder(data.order);
            }
            sendPurchasedEvent(
              paymentSelectedType.kind,
              "inAnalysis",
              total_price,
              subtotal_with_additions,
              order_number
            );
          }
        });
      } else {
        setResolvedRecaptcha(false);
        setLoading(false);
        notification("Preencha todos os campos", "danger");
      }
    }
  };

  useEffect(() => {
    if (resolvedRecaptcha) {
      onSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolvedRecaptcha])

  function payOnline(new_order) {
    const { anti_fraud_app_code } = market;
    const params = generateOnlinePayParams(market, deliveryMethod, userInfo, new_order, paymentInfosEncrypted, paymentSelectedType);

    payWithMerconnectPay(params, (error, data) => {
      if (error) {
        setLoading(false);
        setResolvedRecaptcha(false);
        createLog(JSON.stringify(error.response.data), new_order.id);
        notification(
          "Ocorreram erros ao processar seu pagamento, verifique as informações do seu cartão e tente novamente.",
          "danger"
        );
      } else {
        if (sessionIdReceiver.current.value || anti_fraud_app_code) {
          verifyStatusAntiFraud(new_order)
        } else {
          trackCustom("OnlinePurchase");
          dispatch(setOrderId(new_order.id));
          dispatch(setOrderNumber(new_order.order_number));
          setOrder(new_order);
          setLoading(false);
        }
      }
    });
  }

  function payPix(new_order) {
    const params = generateOnlinePayParams(market, deliveryMethod, userInfo, new_order, paymentInfosEncrypted, paymentSelectedType);

    payWithMerconnectPay(params, (error, data) => {
      if (error) {
        setLoading(false);
        setResolvedRecaptcha(false);
        createLog(JSON.stringify(error.response), new_order.id);
        notification(
          "Ocorreram erros ao processar seu pagamento, verifique as informações do seu cartão e tente novamente.",
          "danger"
        );
      } else {
        trackCustom("OnlinePurchase");
        dispatch(setOrderId(new_order.id));
        dispatch(setOrderNumber(new_order.order_number));
        setLoading(false);
        setOrder(new_order);

        localStorage.setItem("last_id_pix_order", new_order.id);
        localStorage.setItem(
          "last_pix_expires_at",
          data.online_buy_pix.pix_expires_at
        );

        showNotification(
          "top",
          "center",
          "Sua compra foi realizada com sucesso!",
          "success",
          1250
        );
        history.push("/order?orderId=" + new_order.id);
      }
    });
  }

  const verifyStatusAntiFraud = (orderId) => {
    let intervalId;
    function finalizeCheckStatus() {
      clearInterval(intervalId);
      setShowOrderStatus(false);
      trackCustom("OnlinePurchase");
      dispatch(setOrderId(orderId.id));
      dispatch(setOrderNumber(orderId.order_number));
      setOrder(orderId);
      setLoading(false)
    }
    const checkStatus = () => {
      getOrderStatus(orderId.id, (error, data) => {
        if (error) {
          console.log(error)
        } else {
          if (data.order.status === "waiting") {
            setShowOrderStatus(true)
          } else {
            finalizeCheckStatus()
          }
          setCheckOrderStatus(data.order.status)
        }
      })
    }
    intervalId = setInterval(checkStatus, 3000)
    setTimeout(() => {
      finalizeCheckStatus()
    }, 180000);
  }

  const validationMessageCart = () => {
    const validationMessage = orderValidationMessage();
    if (validationMessage.length !== 0) {
      confirmAlert({
        title: validationMessage,
        buttons: [{ label: "Ok" }],
      });
      history.push(`/pagamento/${market.id}`);
    }
  };

  useEffect(() => {
    const validationMessage = orderValidationMessage();
    if (validationMessage.length !== 0) {
      confirmAlert({
        title: validationMessage,
        buttons: [{ label: "Ok" }],
      });
    }
  }, [cart]);

  const orderValidationMessage = (can_check) => {
    const method_delivery = deliveryMethod.deliveryMethod;
    if (total_products < method_delivery.min_order_price && !can_check) {
      return `O valor mínimo da compra é de ${numberToCurrency(
        method_delivery.min_order_price
      )}`;
    }
    if (
      countItensCart(cart) > method_delivery.max_items_amount &&
      method_delivery.max_items_amount !== null
    ) {
      const amountToRemove =
        countItensCart(cart) - method_delivery.max_items_amount;
      return `Você ultrapassou a quantidade máxima de itens (${method_delivery.max_items_amount}). Retire ${amountToRemove} produtos do seu carrinho para continuar.`;
    }

    if (
      cart.length > method_delivery.max_distinct_items &&
      method_delivery.max_distinct_items !== null
    ) {
      const amountToRemove = cart.length - method_delivery.max_distinct_items;
      return `Você ultrapassou a quantidade máxima de itens distintos (${method_delivery.max_distinct_items}). Retire ${amountToRemove} produtos do seu carrinho para continuar.`;
    }

    return "";
  };

  const getExpressOperatingTime = () => {
    return timeZoneAdjustedMoment(new Date())
      .add(deliveryMethod.deliveryMethod.preparation_time || 0, "m")
      .toISOString();
  };

  const isExpress = () => {
    return deliveryMethod.deliveryMethod.preparation_time ? true : false;
  };


  useEffect(() => {
    const currentRecaptcha = recaptcha?.current
    if (currentRecaptcha?.reset !== undefined) {
      currentRecaptcha.reset()
    }
  });

  const handleBrandCashbackCoupons = (data) => {
    const cashbackCoupons = data.cashback_coupons || []
    setBrandCashbackCoupons(cashbackCoupons)
  }

  return (
    <>
      <Recaptcha
        locale="pt-br"
        ref={recaptcha}
        sitekey={getRecaptchaSiteKey()}
        onResolved={() => setResolvedRecaptcha(true)
        }
        badge="bottomleft"
      />
      {order.id ? (
        <OrderFinalized
          order={order}
          market={market}
          paymentType={paymentSelectedType}
          isExpress={isExpress()}
          deliveryTime={
            isExpress() ? getExpressOperatingTime() : deliveryTime
          }
          statusOrder={checkOrderStatus}
          postalData={selectedPostalData}
          cashbackCoupon={brandCashbackCoupons?.[0]}
          hasItemsWithRevenue={hasItemsWithRevenue}
        />)
        :
        (<Container className='mt-5'>

          <input
            type="hidden"
            ref={sessionIdReceiver}
            name="antifraud_session_id"
            id="clearsaleSessionIdReceiver"
          />
          <SimpleDialogs
            isOpen={showOrderStatus}
            simpleParagraph={"Por favor, aguarde. Estamos processando o seu pagamento."}
          />
          {(loading || resolvedRecaptcha)
            &&
            (<Backdrop style={{ color: "#fff", zIndex: 999 }} open={loading}>
              <CircularProgress color="primary" />
            </Backdrop>
            )}
          {deliveryMethod.deliveryMethod.id && (
            <>
              <IconButton
                className="mt-5 mb-3"
                style={{ borderRadius: 5 }}
                size="small"
                onClick={() => {
                  history.goBack();
                }}
                color="primary"
              >
                <ArrowBack fontSize="inherit" />
                Voltar
              </IconButton>
              <Grid container spacing={2}>
                <Warning products={productsWithPrepTime} message={market.times_message} />
                {cart &&
                  <OrderRelatedItems cart={cart} />}
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <OrderDetails
                    request_custom_bags={request_custom_bags}
                    discounts={discounts.discounts}
                    total_discounts={discounts.total_discount}
                    correioDeliveryTax={selectedPostalData}
                  />
                  <WithholdRevenueWarning show={hasItemsWithRevenue} sx={{ marginTop: "1rem" }} />
                  <OrderItems
                    discounts={discounts.discounts}
                    getApiDiscounts={getApiDiscounts}
                    market={market}
                    orderValidationMessage={orderValidationMessage}
                  />
                  <OrderClientInfos
                    cart={cart}
                    firebaseRecoveredInfo={firebaseRecoveredInfo}
                    formError={formError}
                    signedIn={signedIn}
                  />
                  <OrderDeliveryInfos
                    market={market}
                    deliveryMethod={deliveryMethod.deliveryMethod}
                    formError={formError}
                    showAdrressModal={showAdrressModal}
                    setShowAddressModal={setShowAddressModal}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <OrderCoupon
                    getApiDiscounts={getApiDiscounts}
                    discounts={discounts.discounts}
                    coupons={coupons}
                  />
                  <OrderPreferences
                    setMissingItems={setMissingItems}
                    setCustomBags={setCustomBags}
                    missing_items={missing_items}
                    custom_bags={custom_bags}
                    setRequestBags={setRequestBags}
                    request_custom_bags={request_custom_bags}
                    total_cart_amount={total_products}
                    preferences={preferences}
                    setPreferences={setPreferences}
                    postalMethodValues={postalMethodValues}
                    loading={componentLoading}
                  />
                  <OrderDeliveryTime
                    deliveryMethod={deliveryMethod.deliveryMethod}
                    showDeliveryTimesModal={showDeliveryTimesModal}
                    setShowDeliveryTimesModal={setShowDeliveryTimesModal}
                    operatingTimes={operatingTimes}
                    formError={formError}
                    setDeliveryTime={setDeliveryTime}
                    postalMethodValues={postalMethodValues}
                    serviceCodeCorreios={serviceCodeCorreios} 
                    handleChangeValueCorreios={handleChangeValueCorreios}
                    loading={componentLoading}
                    isExpress={isExpress(deliveryMethod.deliveryMethod)}
                  />
                  <OrderPaymentInfos
                    cash={cash}
                    total={total_value}
                    setPaymentCash={setCash}
                    formError={formError}
                    setShowModal={setShowCardModalInfo}
                    showModal={cardModalInfo}
                    setSelectedPaymentType={(value) =>
                      setPaymentSelectedType({ ...value })
                    }
                    payment_selected_type={paymentSelectedType}
                    market={market}
                    deliveryMethod={deliveryMethod.deliveryMethod}
                    setNumberCreditInstallments={(value) => setNumberCreditInstallments(value)}
                    setEncryptedInfos={setPaymentInfosEncrypted}/>
                </Grid>
                <Box pt={2} pb={4} style={{ width: "100%" }} display="flex" justifyContent={"flex-end"}>
                  <Button
                    onClick={() => executeRecaptcha()}
                    variant="contained"
                    color="primary"
                  >
                    Finalizar pedido
                  </Button>
                </Box>
              </Grid>
            </>
          )}

          <ConfirmTermsModal
            privacyPolicy={privacyPolicy}
            isOpen={termsOfUseVersionModal}
            toggleModal={() => setTermsOfUseVersionModal(false)}
          />
          <NotificationCenterWarningModal
            isOpen={notificationModalOpen}
            toggleModal={() => setNotificationModalOpen(false)}
          />
        </Container>
        )}
    </>
  );
}

export default Checkout;
